import React from 'react'
import { graphql } from 'gatsby'
import { Layout,
         ResumeExperienceItem,
         DividerHeading,
         ListTwoCol } from '../components/common'

const Resume = ({ data }) => {
  const resumeData = data.allResumeYaml.edges[0].node

  return (
    <Layout>
      <div className="container container--narrow">
        <article className="content">
          <h1 className="content-title">
            Resume
          </h1>

          <DividerHeading>
            Employment
          </DividerHeading>

          {resumeData.jobs.map((item, index) => (
            <ResumeExperienceItem
              key={index}
              title={`${item.role}, ${item.company}`}
              date={jobDateText(item)}
              location={item.location}
              className="mb-8"
            >
              <ul className="mt-4">
                {item.bullets.map((bullet, index) => (
                  <li key={index}>
                    { bullet }
                  </li>
                ))}
              </ul>
            </ResumeExperienceItem>
          ))}

          <DividerHeading>
            Education
          </DividerHeading>

          {resumeData.education.map((item, index) => (
            <ResumeExperienceItem
              key={index}
              title={item.institution}
              date={item.year}
              location={item.location}
              className="mb-8"
            >
              <p className="-mt-4" dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </ResumeExperienceItem>
          ))}

          <DividerHeading>
            Skills
          </DividerHeading>

          <ListTwoCol items={resumeData.skills}/>

          <DividerHeading>
            Tools & Tech
          </DividerHeading>

          <ListTwoCol items={resumeData.tools}/>
        </article>
      </div>
    </Layout>
  )
}

function jobDateText(jobItem) {
  return (
    <>
      {jobItem.startDate} – <br className="hidden md:inline"></br>
      {jobItem.endDate}
    </>
  )
}

export default Resume

export const resumeQuery = graphql `
  query resumeJsonQuery {
    allResumeYaml {
      edges {
        node {
          jobs {
            bullets
            company
            endDate
            location
            role
            startDate
          }
          education {
            description
            institution
            location
            year
          }
          skills
          tools
        }
      }
    }
  }
`
